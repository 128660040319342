<template>
    <v-card class="mt-3" :loading="loading">
        <v-card-text>
            <h2 class="text-h6 mb-3">{{ $t('AdminWhitelabel.content.tools.label') }}</h2>
            <language-selector v-model="locale" :languages="languages" separator="_" />
            <custom-content v-model="content" :primary-color="primaryColor" />
        </v-card-text>

        <v-card-actions class="mb-2 ml-2">
            <v-btn
                :loading="loading"
                @click="saveContent"
            >
                <v-icon start>mdi-content-save</v-icon>
                {{ $t('OneWord.save') }}
            </v-btn>
        </v-card-actions>
    </v-card>

    <testament-completed-banner :languages="languages" :banners="banners" :banner-url="bannerUrl" />
</template>

<script>
import CustomContent from './whitelabel/CustomContent';
import TestamentCompletedBanner from './whitelabel/TestamentCompletedBanner';
import LanguageSelector from '@/components/LanguageSelector';
import {mapGetters} from 'vuex';
import {EventBus} from '@/utils/event-bus';

export default {
    name: 'AdminWhitelabel',
    components: {
        CustomContent,
        LanguageSelector,
        TestamentCompletedBanner,
    },
    beforeRouteLeave(to, from, next) {
        if (this.unsavedChanges) {
            this.$root.$confirm(
                this.$t('OneWord.confirmUnsavedChangesTitle'),
                this.$t('OneWord.confirmUnsavedChangesInfoText'),
            ).then(confirm => {
                confirm ? next() : next(false);
            });
        } else {
            next();
        }
    },
    data() {
        return {
            loading: false,
            locale: 'de_CH',
            unsavedChanges: false,
            translatedContent: {},
            primaryColor: '#2F1F3C',
            languages: {},
            banners: {},
            bannerUrl: {},
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        content: {
            get() {
                return this.translatedContent[this.locale];
            },
            set(value) {
                if (value) {
                    _.set(this.translatedContent, this.locale, value);
                } else {
                    delete this.translatedContent[this.locale];
                }
            }
        }
    },
    watch: {
        translatedContent: {
            deep: true,
            handler(newVal, oldVal) {
                if (JSON.stringify(oldVal) !== '{}') {
                    this.unsavedChanges = true;
                }
            },
        },
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            axios.get(`/webapi/accounts/${this.user.account_id}/whitelabel`)
                .then(response => {
                    this.unsavedChanges = false;
                    this.translatedContent = response.data.content;
                    this.primaryColor = response.data.colors?.primary || '#2F1F3C';
                    this.languages = response.data.languages;
                    this.banners = response.data.testament_complete_banner;
                    this.bannerUrl = response.data.testament_completed_banner_url;
                }).finally(() => {
                    this.loading = false;
                });
        },
        saveContent() {
            this.loading = true;
            axios.put(`/webapi/accounts/${this.user.account_id}/whitelabel`, {
                content: this.translatedContent
            })
                .then(() => {
                    this.unsavedChanges = false;
                    EventBus.$emit('success');
                }).catch(error => {
                    EventBus.$emit('error', _.get(error, 'response.data.message'));
                }).finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>

</style>
