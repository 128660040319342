<template>
    <v-card class="mt-2">
        <v-card-text
            :class="{
                'pa-10': $vuetify.display.lgAndUp,
                'pa-2': $vuetify.display.mdAndDown,
            }"
        >
            <v-row>
                <v-col class="hidden-md-and-down" lg="1">
                    <v-avatar color="accent" size="60">
                        <v-icon theme="dark" size="40">mdi-thumb-up</v-icon>
                    </v-avatar>
                </v-col>
                <v-col cols="12" lg="11">
                    <div class="text-h6 mb-4 mt-3">
                        {{ $t("TestamentCreated.title") }}
                    </div>
                </v-col>
            </v-row>

            <v-card class="bg-pen-and-paper mb-8 mt-4">
                <v-card-text class="pa-6">
                    <span class="text-h6">
                        {{ $t("TestamentCreated.card1.title") }}
                    </span>
                    <ol class="my-6 list-decimal" type="a">
                        <li class="my-2" v-html="$t('TestamentCreated.card1.line1')" />
                        <li class="my-2" v-html="$t('TestamentCreated.card1.line2')" />
                        <li class="my-2" v-html="$t('TestamentCreated.card1.line3')" />
                        <li class="my-2" v-html="$t('TestamentCreated.card1.line4')" />
                    </ol>

                    <a
                        :data-consultingcode="testament.consulting_code"
                        :href="testament.url_pdf"
                        class="block rounded bg-da-dark py-4 pl-6 pr-8 font-semibold text-white hover:bg-da-pp lg:inline-block"
                        data-cy="testament-created-btn-pdf-download"
                        download
                    >
                        <v-icon color="white" start>mdi-file-outline</v-icon>
                        {{ $t("TestamentCreated.pdfDownload") }}
                    </a>

                    <button
                        class="mt-2 block w-full rounded py-4 pl-6 pr-8 text-left font-semibold outline outline-1 hover:bg-da-pp hover:text-white lg:ml-4 lg:inline-block lg:w-auto"
                        @click="$router.push({ name: 'my-documents' })"
                    >
                        <v-icon start>mdi-arrow-right</v-icon>
                        {{ $t("App.nav.dashboard") }}
                    </button>

                    <button
                        class="mt-2 block w-full rounded py-4 pl-6 pr-8 text-left font-semibold outline outline-1 hover:bg-da-pp hover:text-white lg:ml-4 lg:inline-block lg:w-auto"
                        @click="restart()"
                    >
                        <v-icon start>mdi-arrow-u-left-top</v-icon>
                        {{ $t("TestamentCreated.newTestament") }}
                    </button>

                    <div v-if="showHomepageButton" class="mt-8 w-full text-left">
                        <a
                            :href="getHomepage"
                            class="block rounded py-4 pl-6 pr-8 font-semibold text-inherit outline outline-1 hover:bg-da-pp hover:text-white lg:inline-block"
                        >{{
                            $t("TestamentCreated.btn.backToOrganization", {
                                organizationName: getName,
                            })
                        }}</a>
                    </div>
                </v-card-text>
            </v-card>

            <a
                v-if="bannerUrl && bannerDesktop?.url"
                :href="bannerUrl"
                class="mb-8 block"
                target="_blank"
                rel="noopener"
            >
                <picture>
                    <source
                        v-if="bannerMobile?.url"
                        :srcset="bannerMobile.url"
                        media="(max-width: 600px)"
                    >
                    <img
                        v-if="bannerDesktop.url"
                        class="w-full"
                        :src="bannerDesktop.url"
                        :alt="bannerDesktop.name"
                    >
                </picture>
            </a>

            <more-documents :buttons="moreDocumentButtons" />
        </v-card-text>

        <v-dialog
            v-model="ngoThanksDialog"
            :fullscreen="$vuetify.display.mdAndDown"
            max-width="800"
            persistent
            @close="ngoThanksDialog = false"
        >
            <ngo-thanks-dialog
                tool="testament"
                :ngo-thanks="ngoThanks"
                :ngo-info="ngoInfo"
                :profiles="ngoThanksProfiles"
                @save="saveNgoThanks"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/utils/event-bus";
import MoreDocuments from "@/components/Upselling/MoreDocuments";
import NgoThanksDialog from "@/components/NgoThanksDialog";
import { checkCompletion } from "@/composables/ToolStepper";

export default {
    name: "TestamentCreated",

    components: {
        MoreDocuments,
        NgoThanksDialog,
    },

    data() {
        return {
            email: null,
            loading: false,
            emailError: false,
            profileSuccess: false,
            ngoThanksDialog: false,
            ngoThanks: false,
            ngoInfo: false,
            VUE_APP_DA_PUBLIC_URL: process.env.VUE_APP_DA_PUBLIC_URL,
        };
    },

    computed: {
        ...mapGetters("affiliateProfile", {
            affiliateProfile: "profile",
            hasAffiliateProfile: "hasProfile",
        }),
        ...mapGetters("user", ["isAuthenticated"]),
        ...mapGetters("testament", [
            "testament",
            "testator",
            "ngos",
            "testamentPeople",
            "ngoThanksSubmitted",
        ]),
        ...mapGetters("env", [
            "isDa",
            "isGermany",
            "getName",
            "getHomepage",
            "getTestamentCompletedBanner",
            "getTestamentCompletedBannerUrl",
        ]),

        moreDocumentButtons() {
            let buttons = [];

            if (this.$hasFeature(this.$Features.PatientDecree)) {
                buttons.push({
                    icon: "mdi-file-star-outline",
                    label: this.$t("OneWord.patientDecree"),
                    to: { name: "patient-decree-tool" },
                    id: "testament-created-btn-patient-decree",
                });
            }

            if (this.$hasFeature(this.$Features.AdvanceCareDirective)) {
                buttons.push({
                    icon: "mdi-file-account-outline",
                    label: this.$t("AppUserNav.advance_care_directive"),
                    to: { name: "advance-care-directive-tool" },
                    id: "testament-created-btn-advance-care-directive",
                });
            }

            return buttons;
        },
        ngoThanksProfiles() {
            return this.ngos;
        },
        showHomepageButton() {
            return !(this.isDa || this.isGermany);
        },
        getLang() {
            return this.$vuetify.locale.current;
        },
        bannerMobile() {
            let url = _.get(
                this.getTestamentCompletedBanner,
                `${this.getLang}.mobile`
            );

            return url ? url : _.get(
                this.getTestamentCompletedBanner,
                `de.mobile`
            );
        },
        bannerDesktop() {
            let url =  _.get(
                this.getTestamentCompletedBanner,
                `${this.getLang}.desktop`
            );

            return url ? url : _.get(
                this.getTestamentCompletedBanner,
                `de.desktop`
            );
        },
        bannerUrl() {
            return _.get(
                this.getTestamentCompletedBannerUrl,
                `${this.getLang}`
            );
        },
    },

    mounted() {
        checkCompletion("testament");

        if (!this.ngoThanksSubmitted) {
            this.openNgoThanks();
        }
        window.scrollTo(0, 0);
    },

    methods: {
        ...mapActions("testament", ["restart", "setNgoThanksSubmitted"]),
        openLink(location) {
            this.$router.push(location);
        },
        createProfile() {
            this.loading = true;
            this.emailError = false;
            axios
                .post("/webapi/users/add", {
                    email: this.email,
                    testament: this.testament.secure_id,
                    sponsored_by: this.hasAffiliateProfile
                        ? this.affiliateProfile.id
                        : null,
                })
                .then(() => {
                    this.profileSuccess = true;
                    loadEnv();
                })
                .catch(() => {
                    this.emailError = true;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openUrl(url) {
            window.open(url, "_blank");
        },
        openNgoThanks() {
            // Is there any NGO in the will, also with 0 values?
            let ngos = _.filter(this.testamentPeople, {
                type: "ngo",
                is_additional: false,
            });

            if (ngos.length > 0) {
                this.ngoThanksDialog = true;
            }

            // Is there a sponsor available?
            if (this.hasAffiliateProfile) {
                this.ngoThanksDialog = true;
            }
        },
        saveNgoThanks(settings) {
            if (settings) {
                this.ngoThanks = settings.ngoThanks;
                this.ngoInfo = settings.ngoInfo;

                axios
                    .post(
                        "/webapi/testaments/ngo-settings",
                        Object.assign(settings, {
                            testament: this.testament.secure_id,
                        })
                    )
                    .then(() => {
                        this.setNgoThanksSubmitted();
                        EventBus.$emit(
                            "success",
                            this.$t("TestamentCreated.ngoThanksConfirm")
                        );
                    })
                    .finally(() => {
                        this.ngoThanksDialog = false;
                    });
            } else {
                this.ngoThanksDialog = false;
            }
        },
        newTestament() {
            this.resetTestament();
            this.$router.push({ name: "testament-tool" });
        },
        partnerTypes(profile) {
            return _(profile.partner_types)
                .reduce((res, item) => {
                    res.push(item.name);
                    return res;
                }, [])
                .join(", ");
        },
        hasListThumbnail(profile) {
            return (
                _.has(profile, "list_thumbnail") && !_.isEmpty(profile.list_thumbnail)
            );
        },
        listThumbnail(profile) {
            return _.get(profile, "list_thumbnail");
        },
        open(profile) {
            window.open(
                `${this.VUE_APP_DA_PUBLIC_URL}/dienstleisterverzeichnis/${profile.slug}`,
                "_blank"
            );
        },
    },
};
</script>

<style lang="scss" scoped></style>
