import {nextTick} from 'vue';
import {createRouter, createWebHistory, isNavigationFailure} from 'vue-router';
import Login from '../pages/Login';
import Register from '../pages/Register';
import SendPassword from '../pages/SendPassword';
import SetPassword from '../pages/SetPassword';
import User from '../pages/User';
import MyDocuments from '../pages/my-documents/MyDocuments';
import TestamentCreated from '../pages/testament/TestamentCreated';
import TestamentTool from '../pages/testament/TestamentTool';
import PatientDecreeTool from '../pages/patient-decree/PatientDecreeTool';
import AdvanceCareDirectiveTool from '../pages/advance-care-directive/AdvanceCareDirectiveTool';
import AdvanceCareDirectiveCreated from '../pages/advance-care-directive/AdvanceCareDirectiveCreated';
import AdminAccount from '../pages/admin/AdminAccount';
import AdminProfileList from '../pages/admin/AdminProfileList';
import AdminProfileCreate from '../pages/admin/AdminProfileCreate';
import AdminProfile from '../pages/admin/AdminProfile';
import ProfileDescription from '../pages/admin/profile/ProfileDescription';
import ProfileContact from '../pages/admin/profile/ProfileContact';
import ProfileImages from '../pages/admin/profile/ProfileImages';
import AdminWhitelabel from '../pages/admin/AdminWhitelabel';
import AdminWhitelabelColors from '../pages/admin/AdminWhitelabelColors';
import AdminWhitelabelContent from '../pages/admin/AdminWhitelabelContent';
import AdminSubscription from '../pages/admin/AdminSubscription';
import AdminStatistic from '../pages/admin/AdminStatistic';
import AdminTools from '../pages/admin/AdminTools';
import AdminDownloads from '../pages/admin/AdminDownloads';
import NgoProfile from '../components/Profile/NgoProfile';
import Error404 from '../pages/errors/Error404';
import Testing from '../pages/Testing';
import AdminWebsiteRanking from '../pages/admin/AdminWebsiteRanking';
import AdminWebsiteRankingDetail from '../pages/admin/AdminWebsiteRankingDetail';
import Error403 from '../pages/errors/Error403';
import UserProfile from '../pages/user/UserProfile';
import UserAccount from '../pages/user/UserAccount';
import UserNotifications from '../pages/user/UserNotifications';
import UserSecurity from '../pages/user/UserSecurity';
import UserDeletion from '../pages/user/UserDeletion';
import store from '../store';
import PatientDecreeCreated from '../pages/patient-decree/PatientDecreeCreated';
import AffiliateSwitch from '../pages/affiliate/AffiliateSwitch';
import AdminLeads from "../pages/admin/AdminLeads";
import AdminLeadEdit from "../pages/admin/AdminLeadEdit";
import ConsultingCreated from "../pages/consulting/ConsultingCreated";
import TestamentInvalidAffiliateLink from "../pages/affiliate/TestamentInvalidAffiliateLink";
import ProfileWebinarList from "../pages/webinars/ProfileWebinarList.vue";
import ProfileWebinarDetails from "../pages/webinars/ProfileWebinarDetails";
import {usei18n} from '@/plugins/i18n';
import { Features } from "@/plugins/features";
import { getHighestVisitedStep } from '@/composables/ToolStepper';
import WebinarDetailsRegistration from "@/pages/webinars/WebinarDetailsRegistration";

const routes = [
    {
        path: '/',
        redirect: 'login',
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: (to, from, next) => {
            // if user is logged in...
            if (store.getters['env/isAuthenticated']) {
                // ...and user has an account...
                if ($ability.can('read', 'Account')) {
                    // ...show account statistic page
                    next('/admin/statistic')
                } else {
                    // ...otherwise show "my documents"
                    next('/my-documents')
                }
            } else {
                // user not logged in, show login page
                next()
            }
        },
        props: true,
    }, {
        path: '/login/:profile_slug',
        name: 'login-affiliate',
        component: Login,
    }, {
        path: '/register',
        name: 'register',
        component: Register,
    }, {
        path: '/send-password',
        name: 'send-password',
        component: SendPassword,
    }, {
        path: '/set-password',
        name: 'set-password',
        component: SetPassword,
    }, {
        path: '/user',
        component: User,
        children: [
            {
                path: 'profile',
                name: 'user-profile',
                component: UserProfile,
            }, {
                path: 'notifications',
                name: 'user-notifications',
                component: UserNotifications,
            }, {
                path: 'account',
                name: 'user-account',
                component: UserAccount,
            }, {
                path: 'security',
                name: 'user-security',
                component: UserSecurity,
            }, {
                path: 'deletion',
                name: 'user-deletion',
                component: UserDeletion,
            }, {
                path: 'verified',
                redirect: {name: 'user-account'},
            }, {
                path: '',
                redirect: {name: 'user-profile'},
            },
        ],
    }, {
        path: '/user/verified',
        name: 'user-verified',
        component: User,
        props: {
            verified: true,
        },
    }, {
        path: '/my-documents',
        name: 'my-documents',
        component: MyDocuments,
        meta: {
            permission: {
                verb: 'read',
                subject: 'User',
            },
        },
    }, {
        path: '/testamentgenerator',
        redirect: {name: 'testament-tool'},
    }, {
        path: '/testamentgenerator/:profile_slug',
        component: TestamentInvalidAffiliateLink,
    }, {
        path: '/testament',
        redirect: {name: 'testament-tool'},
    }, {
        path: '/testament/step/:step?',
        name: 'testament-tool', // do NOT change without refactoring dependencies
        component: AffiliateSwitch,
        beforeEnter(to, from, next) {
            if (typeof to.params.step === 'undefined') {
                next({
                    name: to.name,
                    params: {step: getHighestVisitedStep('testament')},
                    query: to.query,
                });
            } else {
                next();
            }
        },
        meta: {
            title: 'AppUserNav.testament',
            feature: Features.Testament,
        },
    }, {
        path: '/testament/completed',
        name: 'testament-completed', // do NOT change without refactoring dependencies
        component: TestamentCreated,
        meta: {
            title: 'AppUserNav.testament',
            feature: Features.Testament,
        },
    }, {
        path: '/testament/:testament_id(\\d+)',
        name: 'testament-edit',
        component: TestamentTool,
        meta: {
            title: 'AppUserNav.testament',
            feature: Features.Testament,
        },
    }, {
        path: '/testament/:profile_slug',
        redirect: {name: 'testament-tool'},
    }, {
        path: '/patientenverfuegung',
        redirect: {name: 'patient-decree-tool'},
    }, {
        path: '/patientenverfuegung/step/:step?',
        name: 'patient-decree-tool', // do NOT change without refactoring dependencies
        component: AffiliateSwitch,
        beforeEnter(to, from, next) {
            if (typeof to.params.step === 'undefined') {
                next({
                    name: to.name,
                    params: {step: getHighestVisitedStep('patientDecree')},
                    query: to.query,
                });
            } else {
                next();
            }
        },
        meta: {
            title: 'AppUserNav.patientenverfuegung',
            feature: Features.PatientDecree,
        },
    }, {
        path: '/patientenverfuegung/completed',
        name: 'patient-decree-completed', // do NOT change without refactoring dependencies
        component: PatientDecreeCreated,
        meta: {
            title: 'AppUserNav.patientenverfuegung',
            feature: Features.PatientDecree,
        },
    }, {
        path: '/patientenverfuegung/:patient_decree_id(\\d+)',
        name: 'patientenverfuegung-edit',
        component: PatientDecreeTool,
        meta: {
            title: 'AppUserNav.patientenverfuegung',
            feature: Features.PatientDecree,
        },
    }, {
        path: '/patientenverfuegung/:profile_slug',
        redirect: {name: 'patient-decree-tool'},
    }, {
        path: '/vorsorgeauftrag',
        redirect: {name: 'advance-care-directive-tool'},
    }, {
        path: '/vorsorgeauftrag/step/:step?',
        name: 'advance-care-directive-tool', // do NOT change without refactoring dependencies
        component: AffiliateSwitch,
        beforeEnter(to, from, next) {
            if (typeof to.params.step === 'undefined') {
                next({
                    name: to.name,
                    params: {step: getHighestVisitedStep('advanceCareDirective')},
                    query: to.query,
                });
            } else {
                next();
            }
        },
        meta: {
            title: 'AppUserNav.advance_care_directive',
            feature: Features.AdvanceCareDirective,
        },
    }, {
        path: '/vorsorgeauftrag/completed',
        name: 'advance-care-directive-completed', // do NOT change without refactoring dependencies
        component: AdvanceCareDirectiveCreated,
        meta: {
            title: 'AppUserNav.advance_care_directive',
            feature: Features.AdvanceCareDirective,
        },
    }, {
        path: '/vorsorgeauftrag/:advance_care_directive_id(\\d+)',
        name: 'advance-care-directive-edit',
        component: AdvanceCareDirectiveTool,
        meta: {
            title: 'AppUserNav.advance_care_directive',
            permission: {
                verb: 'read',
                subject: 'User',
            },
            feature: Features.AdvanceCareDirective,
        },
    }, {
        path: '/vorsorgeauftrag/:profile_slug+',
        redirect: {name: 'advance-care-directive-tool'},
    }, {
        path: '/consulting',
        redirect: {name: 'consulting-tool'},
    }, {
        path: '/consulting/step/:step?',
        name: 'consulting-tool', // do NOT change without refactoring dependencies
        component: AffiliateSwitch,
        beforeEnter(to, from, next) {
            if (typeof to.params.step === 'undefined') {
                next({name: to.name, params: {step: getHighestVisitedStep('consulting')}});
            } else {
                next();
            }
        },
        meta: {
            title: 'AdminTools.backlinks.consulting',
            feature: Features.Consulting,
        },
    }, {
        path: '/consulting/completed',
        name: 'consulting-completed', // do NOT change without refactoring dependencies
        component: ConsultingCreated,
        meta: {
            title: 'AdminTools.backlinks.consulting',
            feature: Features.Consulting,
        },
    }, {
        path: '/admin/account',
        name: 'admin-account',
        component: AdminAccount,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/profiles',
        name: 'admin-profiles',
        component: AdminProfileList,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/profiles/create',
        name: 'admin-profiles-create',
        component: AdminProfileCreate,
        props: true,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/profiles/:profile_id/:lang?',
        component: AdminProfile,
        props: true,
        children: [
            {
                path: 'description',
                name: 'admin-edit-profile-description',
                component: ProfileDescription,
                props: true,
            }, {
                path: 'contact',
                name: 'admin-edit-profile-contact',
                component: ProfileContact,
                props: true,
            }, {
                path: 'images',
                name: 'admin-edit-profile-images',
                component: ProfileImages,
                props: true,
            }, {
                path: '',
                redirect: {name: 'admin-edit-profile-description'},
            },
        ],
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/whitelabel',
        component: AdminWhitelabel,
        children: [
            { 
                path: 'content',
                name: 'admin-whitelabel-content',
                component: AdminWhitelabelContent,
            }, {
                path: 'colors',
                name: 'admin-whitelabel-colors',
                component: AdminWhitelabelColors,
            }, {
                path: '',
                redirect: {name: 'admin-whitelabel-content'},
            },
        ],
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/subscription',
        name: 'admin-subscription',
        component: AdminSubscription,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/statistic',
        name: 'admin-statistic',
        redirect: '/admin/statistic/listing',
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/statistic/:statistic?',
        name: 'admin-statistic-detail',
        component: AdminStatistic,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/tools',
        name: 'admin-tools',
        component: AdminTools,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/downloads',
        name: 'admin-downloads',
        component: AdminDownloads,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    },{
        path: '/admin/support',
        name: 'admin-support',
        beforeEnter(to, from, next) {
            window.open('https://desk.deinadieu.ch/portal/de/kb', '_blank');
            next(false);
        }
    }, {
        path: '/admin/website-ranking',
        name: 'admin-website-ranking',
        component: AdminWebsiteRanking,
        meta: {
            permission: {
                verb: 'disable_access', // read
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/website-ranking/:page_rank',
        name: 'admin-website-ranking-detail',
        component: AdminWebsiteRankingDetail,
        meta: {
            permission: {
                verb: 'disable_access', // read
                subject: 'Account',
            },
        },
    }, {
        path: '/admin/testator',
        name: 'admin-testator',
        redirect: '/admin/leads',
    }, {
        path: '/admin/leads',
        name: 'admin-leads',
        component: AdminLeads,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Testator',
            },
        },
    }, {
        path: '/admin/leads/:lead_id',
        name: 'admin-lead-edit',
        component: AdminLeadEdit,
        meta: {
            permission: {
                verb: 'read',
                subject: 'Testator',
            },
        },
    }, {
        path: '/admin/news',
        name: 'admin-news',
        component: () => import(/* webpackChunkName: "adminNews" */ '../pages/admin/AdminNews'),
        meta: {
            permission: {
                verb: 'read',
                subject: 'Account',
            },
        },
    }, {
        path: '/hilfswerke/:profile_slug',
        name: 'ngo-profile',
        component: NgoProfile,
    }, {
        path: '/webinars',
        beforeEnter: (to, from, next) => {
            // redirect to the webinar overview page of the affiliate
            store.dispatch('affiliateProfile/load').then(() => {
                if (store.getters['affiliateProfile/hasProfile']) {
                    let profile = store.getters['affiliateProfile/profile'];
                    next(`/profiles/${profile.id}/webinars`);
                } else {
                    next('/')
                }
            }).catch(next('/'));
        },
    }, {
        // Name collision with backend /webinars
        path: '/webinar-reg/:webinar_id/registrations',
        name: 'webinars-registrations',
        component: WebinarDetailsRegistration,
    }, {
        path: '/profiles/:profile_id/webinars',
        name: 'webinars',
        component: ProfileWebinarList,
    }, {
        path: '/profiles/:profile_id/webinars/:webinar_id',
        name: 'webinar-details',
        component: ProfileWebinarDetails,
    }, {
        path: '/error-404',
        name: 'error-404',
        component: Error404,
    }, {
        path: '/error-403',
        name: 'error-403',
        component: Error403,
    },
];

if (window.Cypress) {
    routes.push({
        path: '/testing-startpage',
        name: 'testing-startpage',
        component: Testing,
    })
}

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {

    /**
     * Redirect if fullPath begins with a hash (ignore hashes later in path)
     */
    if (to.fullPath.includes('#/')) {
        const path = to.fullPath.substring(to.fullPath.indexOf('#/') + 2);
        next({path: path});
        return;
    }

    /**
     * Feature
     */
    if (_.has(to, 'meta.feature')) {
        if ($hasFeature(to.meta.feature)) {
            next();
        } else {
            next({
                name: 'error-404',
            });
        }
        return;
    }

    /**
     * Permissions
     */
    if (_.has(to, 'meta.permission')) {
        if ($ability.can(to.meta.permission.verb, to.meta.permission.subject)) {
            next();
        } else {
            if (store.getters['env/isAuthenticated']) {
                next(false);
            } else {
                next({name: 'login', query: {path: to.path}});
            }
        }
        return;
    }

    next();
});

router.afterEach((to) => {
    nextTick(() => {
        document.title = store.getters['env/getName'];
        if (to.meta.title) {
            document.title += ' | ' + usei18n().t(to.meta.title);
        }
    });
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }

    return originalPush.call(this, location).catch((err) => {
        if (isNavigationFailure(err)) {
            return err;
        }

        return Promise.reject(err);
    });
};

router.onError(error => {
    // TODO Check the version against a source version
    if (/loading chunk \d* failed./i.test(error.message)) {
        window.location.reload(true);
    }
});

export default router;
