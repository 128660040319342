import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify/lib/framework';
import { mdi } from 'vuetify/iconsets/mdi';
import { en } from 'vuetify/locale';
import { de } from 'vuetify/locale';
import { it } from 'vuetify/locale';
import { fr } from 'vuetify/locale';
import customIcons from './icons';
import {usei18n} from "@/plugins/i18n";
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@/assets/app.scss';
import 'vuetify/styles';
import { VFileUpload } from 'vuetify/lib/labs/VFileUpload';

export default createVuetify({
    locale: {
        locale: usei18n().locale,
        fallback: 'de',
        messages: { en, de, it, fr },
    },
    icons: {
        defaultSet: 'mdi',
        sets: {
            mdi,
            custom: customIcons,
        },
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#2F1F3C',
                    secondary: '#DAC1BA',
                    accent: '#745567',
                    pp: '#CE468A',
                },
            },
        },
        variations: {
            colors: ['primary', 'secondary', 'accent', 'pp'],
            lighten: 2,
            darken: 2,
        },
    },
    defaults: {
        VTextField: {
            variant: 'underlined',
        },
        VSelect: {
            variant: 'underlined',
        },
        VAutocomplete: {
            variant: 'underlined',
        },
        VSwitch: {
            color: 'primary',
        },
        VCardActions: {
            class: 'p-4',
            VBtn: {
                slim: false,
                variant: 'flat',
                color: 'primary',
            },
        },
    },
    components: {
        ...components,
        VFileUpload,
    },
    directives
});
