<template>
    <div>
        <v-card :loading="loading" :disabled="loading" class="mt-2 rounded-lg" elevation="0">
            <div class="block p-4">
                <v-list
                    v-model:selected="selection"
                    :class="$vuetify.display.mdAndDown ? null : 'inline-flex'"
                    select-strategy="classic"
                    nav
                >
                    <v-hover v-for="segment in visibleSegments" :key="segment.key">
                        <template #default="{ isHovering, props }">
                            <v-list-item
                                v-bind="props"
                                :value="segment.key"
                                :class="$vuetify.display.mdAndDown ? 'border-l-4 mb-2' : 'border-t-4 mr-4'"
                                :style="getSegmentStyles(segment)"
                                :data-cy="'segment-' + segment.key"
                                :variant="isHovering ? 'flat' : 'plain'"
                            >
                                <template #prepend="{ isActive }">
                                    <v-list-item-action class="self-center">
                                        <v-checkbox :model-value="isActive" :color="segment.color" hide-details />
                                    </v-list-item-action>
                                </template>

                                <v-list-item-title>{{ getLabel(segment) }}</v-list-item-title>
                                <v-list-item-subtitle>{{ formatNumber(getSum(segment)) }}</v-list-item-subtitle>
                            </v-list-item>
                        </template>
                    </v-hover>
                </v-list>

                <v-card-text class="pr-0">
                    <div id="lineChart" ref="lineChart" style="width: 100%; height: 400px" />
                </v-card-text>
            </div>
        </v-card>

        <template v-if="selectedStatistic">
            <v-row class="mt-2">
                <v-col
                    v-for="segment in selectedSegments"
                    :key="segment.key"
                    cols="12"
                    :lg="Math.min(6, 12 / selectedSegments.length)"
                >
                    <v-card :data-cy="'segment-info-' + segment.key" class="mt-2 rounded-lg p-4" elevation="0">
                        <v-card-title class="py-2 text-sm">
                            <v-icon size="small" :color="segment.color" class="mr-2 inline-block">mdi-square</v-icon>
                            {{ getTitle(segment) }}
                        </v-card-title>

                        <v-card-text class="text-medium-emphasis" v-html="getInfo(segment)" />

                        <v-chip
                            v-if="showToplisting && selectedStatistic.key === 'testament' && segment.key === 'preview'"
                            class="toplisting ml-3 mt-1 px-3"
                            :class="toplistings.addon_toplisting_testament ? 'toplisting-active' : 'toplisting-inactive'"
                            size="small"
                            variant="flat"
                        >
                            <span v-if="toplistings.addon_toplisting_testament">{{ $t('AdminStatistic.toplisting.active') }}</span>
                            <a v-else :href="$t('AdminStatistic.toplisting.url')" target="_blank">{{ $t('AdminStatistic.toplisting.inactive') }}</a>
                        </v-chip>

                        <template v-if="segment.hasDetails">
                            <div
                                v-for="(detail, index) in segment.getDetails()"
                                :key="index"
                                class="mx-4 mt-4"
                                :class="detail.total ? 'font-medium' : null"
                                data-cy="segment-info-details"
                            >
                                <span class="pr-3" v-html="detail.label" />
                                <v-chip
                                    v-if="showToplisting && detail.toplisting !== undefined"
                                    class="flex-start toplisting px-3 sm:-mt-0.5"
                                    :class="detail.toplisting ? 'toplisting-active' : 'toplisting-inactive'"
                                    size="small"
                                    variant="flat"
                                >
                                    <span v-if="detail.toplisting">{{ detail.toplistingActive }}</span>
                                    <a v-else :href="$t('AdminStatistic.toplisting.url')" target="_blank">{{ detail.toplistingInactive }}</a>
                                </v-chip>
                                <span class="float-right">{{ formatNumber(detail.amount) }}</span>
                                <v-progress-linear
                                    v-if="!detail.total"
                                    :color="segment.color"
                                    :model-value="detail.percentage"
                                    class="mt-2 rounded-none"
                                />
                            </div>
                        </template>

                        <template v-else>
                            <div class="mx-4 mt-4 font-medium">
                                <span>{{ $t('OneWord.total') }}</span>
                                <span class="float-right">{{ formatNumber(getSum(segment)) }}</span>
                            </div>
                        </template>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import axios from 'axios';
import locizeKeys from '@/locize/admin/statistic/ChartSegments';
import { formatDate } from '@/composables/Helpers';
import { mapActions, mapGetters } from 'vuex';
import {Features as $Features} from "@/plugins/features";
import {shallowRef} from "vue";

export default {
    name: 'ChartSegments',
    props: {
        selectedStatistic: Object,
        dateRange: Array,
        toplistings: Object,
    },

    setup() {
        const lineChart = shallowRef(null);
        const chart = shallowRef(); // holds the chart object

        return {
            lineChart,
            chart,
        };
    },
    data() {
        return {
            loading: false,
            // chart: null, // holds the chart object
            selection: ['preview', 'view'],
            chartData: {},
            cancelToken: null,
            chartOptions: {},
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),

        ...mapGetters('env', {
            tenantName: 'getName',
            isSwitzerland: 'isSwitzerland',
        }),

        showToplisting() {
            return this.isSwitzerland;
        },

        fromDate() {
            if (_.first(this.dateRange)) {
                return new Date(_.first(this.dateRange));
            }

            return new Date();
        },

        toDate() {
            if (_.last(this.dateRange)) {
                return new Date(_.last(this.dateRange));
            }

            return new Date();
        },

        profileType() {
            return this.$ability.can('ngo', 'Statistic') ? 'ngo' : 'service-provider';
        },

        chartLabels() {
            const getDaysArray = function (start, end) {
                let arr = [],
                    dt;
                for (arr, dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
                    arr.push(moment(dt).format('DD.MM.YY'));
                }
                return arr;
            };

            return getDaysArray(this.fromDate, this.toDate);
        },

        isNgoTestament() {
            return (
                this.selectedStatistic.key === 'testament' &&
                this.profileType === 'ngo' &&
                this.$ability.can('testament', 'Statistic')
            );
        },

        isNgoConsulting() {
            return (
                this.selectedStatistic.key === 'consulting-contact' &&
                this.profileType === 'ngo'
            );
        },

        segments() {
            let segments = [
                {
                    key: 'preview',
                    color: '#69a2f6',
                    visible: this.profileType === 'ngo' || this.selectedStatistic.key === 'listing',
                    hasDetails: this.profileType === 'ngo' && ['listing', 'consulting-contact'].includes(this.selectedStatistic.key),
                    getDetails: () => {
                        let total = this.getSum({ key: 'preview' }),
                            previewsHome = this.getSum({ key: 'preview_home' }),
                            previewsListing = this.getSum({ key: 'preview_listing' }),
                            previewsTestament = this.getSum({ key: 'preview_testament' });

                        let details = [];

                        if (this.selectedStatistic.key === 'listing') {
                            details = details.concat([
                                {
                                    label: this.$t('AdminStatistic.page.home'),
                                    amount: previewsHome,
                                    percentage: total > 0 ? Math.round((previewsHome / total) * 100) : 0,
                                },
                                {
                                    label: this.$t('AdminStatistic.page.ngoListing'),
                                    amount: previewsListing,
                                    percentage: total > 0 ? Math.round((previewsListing / total) * 100) : 0,
                                    toplisting: this.toplistings.addon_toplisting_directory,
                                    toplistingActive: this.$t('AdminStatistic.toplisting.directory.active'),
                                    toplistingInactive: this.$t('AdminStatistic.toplisting.directory.inactive'),
                                },
                                {
                                    label: this.$t('AdminStatistic.page.testament'),
                                    amount: previewsTestament,
                                    percentage: total > 0 ? Math.round((previewsTestament / total) * 100) : 0,
                                    toplisting: this.toplistings.addon_toplisting_testament,
                                    toplistingActive: this.$t('AdminStatistic.toplisting.active'),
                                    toplistingInactive: this.$t('AdminStatistic.toplisting.inactive'),
                                },
                            ]);
                        }

                        if (this.$hasFeature($Features.Consulting)) {
                            let previewsConsulting = this.getSum({ key: 'preview_consulting' });

                            details.push({
                                label: this.$t('AdminStatistic.page.consulting'),
                                amount: previewsConsulting,
                                percentage:
                                    total > 0 ? Math.round((previewsConsulting / total) * 100) : 0,
                            });
                        }

                        details.push({
                            label: this.$t('OneWord.total'),
                            amount: total,
                            total: true,
                        });

                        return details;
                    },
                },
                {
                    key: 'preview_home',
                    visible: false,
                },
                {
                    key: 'preview_listing',
                    visible: false,
                },
                {
                    key: 'preview_testament',
                    visible: false,
                },
                {
                    key: 'preview_consulting',
                    visible: false,
                },
                {
                    key: 'view',
                    color: '#ad53da',
                    visible: true,
                },
                {
                    key: 'clicks',
                    color: '#ff7e29',
                    visible: true,
                    hasDetails: this.isNgoConsulting || this.isNgoTestament,
                    getDetails: () => {
                        let total = this.getSum({ key: 'clicks' }),
                            viaAffiliate = this.getSum({ key: 'clicks_affiliate' }),
                            viaPortal = this.getSum({ key: 'clicks_portal' });

                        return [
                            {
                                label: this.$t('AdminStatistic.details.viaBacklink'),
                                amount: viaAffiliate,
                                percentage: total > 0 ? Math.round((viaAffiliate / total) * 100) : 0,
                            },
                            {
                                label: this.$t('AdminStatistic.details.viaTenant', {
                                    tenant: this.tenantName,
                                }),
                                amount: viaPortal,
                                percentage: total > 0 ? Math.round((viaPortal / total) * 100) : 0,
                            },
                            {
                                label: this.$t('OneWord.total'),
                                amount: total,
                                total: true,
                            },
                        ];
                    },
                },
                {
                    // only for loading data from backend
                    key: 'clicks_affiliate',
                    visible: false,
                },
                {
                    // only for loading data from backend
                    key: 'clicks_portal',
                    visible: false,
                },
                {
                    key: 'conversion',
                    color: '#6adbb3',
                    visible: true,
                    hasDetails: this.isNgoTestament,
                    getDetails: () => {
                        let total = this.getSum({ key: 'conversion' }),
                            viaAffiliate = this.getSum({ key: 'conversion_affiliate' }),
                            viaPortal = this.getSum({ key: 'conversion_portal' });

                        return [
                            {
                                label: this.$t('AdminStatistic.details.viaBacklink'),
                                amount: viaAffiliate,
                                percentage:
                                    total > 0 ? Math.round((viaAffiliate / total) * 100) : 0,
                            },
                            {
                                label: this.$t('AdminStatistic.details.viaTenant', {
                                    tenant: this.tenantName,
                                }),
                                amount: viaPortal,
                                percentage:
                                    total > 0 ? Math.round((viaPortal / total) * 100) : 0,
                            },
                            {
                                label: this.$t('OneWord.total'),
                                amount: total,
                                total: true,
                            },
                        ];
                    },
                },
                {
                    // only for loading data from backend
                    key: 'conversion_affiliate',
                    visible: false,
                },
                {
                    // only for loading data from backend
                    key: 'conversion_portal',
                    visible: false,
                },
                {
                    key: 'consideration',
                    color: '#a4d021',
                    visible: this.isNgoTestament,
                    hasDetails: true,
                    getDetails: () => {
                        let total = this.getSum({ key: 'consideration' }),
                            viaAffiliate = this.getSum({ key: 'consideration_affiliate' }),
                            viaPortal = this.getSum({ key: 'consideration_portal' });

                        return [
                            {
                                label: this.$t('AdminStatistic.details.viaBacklink'),
                                amount: viaAffiliate,
                                percentage: total > 0 ? Math.round((viaAffiliate / total) * 100) : 0,
                            },
                            {
                                label: this.$t('AdminStatistic.details.viaTenant', {
                                    tenant: this.tenantName,
                                }),
                                amount: viaPortal,
                                percentage: total > 0 ? Math.round((viaPortal / total) * 100) : 0,
                            },
                            {
                                label: this.$t('OneWord.total'),
                                amount: total,
                                total: true,
                            },
                        ];
                    },
                },
                {
                    // only for loading data from backend
                    key: 'consideration_affiliate',
                    visible: false,
                },
                {
                    // only for loading data from backend
                    key: 'consideration_portal',
                    visible: false,
                },
            ];

            return _.filter(segments, (segment) =>
                this.selectedStatistic.types.includes(segment.key)
            );
        },

        visibleSegments() {
            return _.filter(this.segments, (segment) => segment.visible);
        },

        selectedSegments() {
            return _.filter(this.visibleSegments, (segment) =>
                this.selection.includes(segment.key)
            );
        },
    },
    watch: {
        selectedStatistic: {
            handler(statistic) {
                this.selection = statistic.types;
            },
            immediate: true,
        },

        dateRange: {
            handler() {
                this.load();
            },
            immediate: true,
        },

        selection() {
            this.drawChart();
        },

        segments() {
            this.load();
        },
    },

    mounted() {
        this.chart = echarts.init(this.lineChart);

        this.loadUser().finally(this.load);

        window.addEventListener('resize', this.handleResize);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        ...mapActions('user', {
            loadUser: 'load',
        }),

        load() {
            if (_.isEmpty(this.user)) {
                return;
            }

            if (this.loading && this.cancelToken) {
                this.cancelToken.cancel();
                this.loading = false;
                return;
            }

            this.loading = true;
            this.cancelToken = axios.CancelToken.source();
            this.chartData = {};

            let from = formatDate(this.fromDate);
            let to = formatDate(this.toDate);

            let promises = [];
            _.forEach(this.segments, (segment) => {
                promises.push(
                    axios.get(
                        `/webapi/accounts/${this.user.account_id}/statistic?statistic=${this.selectedStatistic.key}&from=${from}&to=${to}&type=${segment.key}`,
                        { cancelToken: this.cancelToken.token }
                    )
                );
            });

            Promise.all(promises)
                .then((results) => {
                    _.forEach(this.segments, (segment, index) => {
                        this.chartData[segment.key] = results[index].data;
                    });

                    this.drawChart();
                })
                .catch(() => {
                    // cancelled requests
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getLabel(segment) {
            return this.$t(
                _.get(
                    locizeKeys,
                    `${this.profileType}.${this.selectedStatistic.key}.label.${segment.key}`
                )
            );
        },

        getTitle(segment) {
            return this.$t(
                _.get(
                    locizeKeys,
                    `${this.profileType}.${this.selectedStatistic.key}.title.${segment.key}`
                )
            );
        },

        getInfo(segment) {
            return this.$t(
                _.get(
                    locizeKeys,
                    `${this.profileType}.${this.selectedStatistic.key}.info.${segment.key}`
                )
            );
        },

        hex2rgba(hex, a) {
            const r = parseInt(hex.slice(1, 3), 16);
            const g = parseInt(hex.slice(3, 5), 16);
            const b = parseInt(hex.slice(5, 7), 16);

            return 'rgba(' + [r, g, b, a].join(',') + ')';
        },

        isActive(segment) {
            return this.selection.includes(segment.key);
        },

        getSum(segment) {
            return parseInt(_.sumBy(this.chartData[segment.key], 'stats'));
        },

        formatNumber(number) {
            return number.toLocaleString(this.user.number_format);
        },

        getSegmentStyles(segment) {
            let styles = {
                minWidth: '200px',
            };

            if (!this.isActive(segment)) {
                return styles;
            }

            return {
                ...styles,
                color: segment.color,
                borderColor: segment.color,
                background: 'linear-gradient(to bottom, ' + this.hex2rgba(segment.color, 0.1) + ', white)',
            };
        },

        chartDataset(chartData) {
            const getDaysArray = function (start, end) {
                let arr = [],
                    dt;

                start.setHours(0, 0, 0);
                end.setHours(23, 59, 59);
                for (arr, dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
                    arr.push(_.get(_.find(chartData, { date: formatDate(dt) }), 'stats', 0));
                }
                return arr;
            };

            return getDaysArray(this.fromDate, this.toDate);
        },

        drawChart() {
            this.chart.clear();

            this.chartOptions = {
                color: [],
                tooltip: {
                    trigger: 'axis',
                },
                grid: {
                    left: '0%',
                    right: '2%',
                    bottom: '4%',
                    top: '3%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                },
                yAxis: {
                    type: 'value',
                },
                legend: {
                    show: false,
                    selected: {},
                },
                series: [],
                animationDuration: 200,
            };

            _.forEach(this.selectedSegments, (segment, index) => {
                this.chartOptions.series[index] = {
                    id: segment.key,
                    type: 'line',
                    showSymbol: false,
                    data: this.chartDataset(this.chartData[segment.key]),
                    emphasis: {
                        itemStyle: {
                            color: segment.color,
                            borderColor: segment.color,
                            borderWidth: 3,
                        },
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [
                                {
                                    offset: 0,
                                    color: this.hex2rgba(segment.color, 0.2),
                                },
                                {
                                    offset: 1,
                                    color: this.hex2rgba(segment.color, 0),
                                },
                            ],
                        },
                    },
                };
            });

            this.chartOptions.xAxis.data = this.chartLabels;
            this.chartOptions.color = _.map(this.selectedSegments, 'color');

            let selected = {};
            _.map(this.segments, (segment) => {
                selected[segment.label] = this.selection.includes(segment.key);
            });

            this.chartOptions.legend.selected = selected;

            this.chart.setOption(this.chartOptions);
            this.handleResize();
        },

        handleResize() {
            this.chart.resize();
        },
    },
};
</script>

<style scoped lang="scss">
.chart {
    max-height: 260px;
}

.v-list-item {
    border-color: #eee;

    &:before {
        opacity: 0;
    }

    &:hover:before {
        border-radius: 6px;
    }

    .v-list-item-title {
        font-weight: normal;
        font-size: 14px;
        color: #333;
        opacity: 1;
    }

    .v-list-item-subtitle {
        font-size: 20px;
        margin-top: 5px;
        font-weight: 700;
        color: #333;
        opacity: 1;
    }
}

.v-list-item--variant-plain {
    opacity: 1 !important;
}

.v-list-item--active {
    .v-list-item-title,
    .v-list-item-subtitle {
        color: inherit !important;
    }
}

.toplisting {
    vertical-align: top;
}

.toplisting-inactive {
    background: #f3f3f3 !important;

    a {
        color: #666;
    }
}

.toplisting-active {
    background: #fff9d4 !important;
    color: #845f04 !important;
}
</style>
