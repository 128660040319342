<script>
import { Form as VeeForm, Field as VeeField } from "vee-validate";
import LanguageSelector from "@/components/LanguageSelector.vue";
import {mapGetters} from "vuex";
import {EventBus} from '@/utils/event-bus';

export default {
    name: "TestamentCompletedBanner",
    components: {
        LanguageSelector,
        VeeField,
        VeeForm,
    },
    props: {
        languages: Object,
        banners: Object,
        bannerUrl: Object,
    },
    data() {
        return {
            loading: false,
            locale: "de_CH",
            localBanners: {
                desktop: null,
                mobile: null,
            },
            localBannerUrl: null,
        };
    },
    computed: {
        ...mapGetters('user', {
            user: 'user',
        }),
        translatedBanner() {
            return _.get(this.localBanners, this.locale, { desktop: null, mobile: null });
        },
        translatedBannerUrl() {
            return _.get(this.localBannerUrl, this.locale);
        },
    },
    watch: {
        banners(value) {
            this.localBanners = _.cloneDeep(value);
        },
        bannerUrl(value) {
            this.localBannerUrl = _.cloneDeep(value);
        }
    },
    methods: {
        uploadFile(file, target) {
            if (_.isUndefined(file)) {
                this.$refs.bannerForm.resetForm();
                return;
            }

            const formData = new FormData();
            formData.append("file", file);
            formData.append("target", target);
            formData.append("locale", this.locale);

            this.loading = true;

            axios
                .post(
                    `/webapi/accounts/${this.user.account_id}/whitelabel/upload`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then(response => {
                    _.merge(this.localBanners, response.data);
                    EventBus.$emit("success", "File uploaded successfully!");
                })
                .catch((error) => {
                    this.$refs.bannerForm.setErrors(error.response.data.errors);
                    EventBus.$emit(
                        "error",
                        _.get(error, "response.data.message", "File upload failed.")
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        deleteFile(file, target) {
            axios
                .delete(`/webapi/accounts/${this.user.account_id}/whitelabel/upload/${file.id}`)
                .then(() => {
                    this.localBanners[this.locale][target] = null;
                    EventBus.$emit("success", "File deleted successfully!");
                })
                .catch((error) => {
                    EventBus.$emit(
                        "error",
                        _.get(error, "response.data.message", "File deletion failed.")
                    );
                });
        },
        saveContent() {
            axios.put(`/webapi/accounts/${this.user.account_id}/whitelabel`, {
                testament_completed_banner_url: this.localBannerUrl
            })
                .then(() => {
                    EventBus.$emit('success');
                }).catch(error => {
                    EventBus.$emit('error', _.get(error, 'response.data.message'));
                }).finally(() => {
                    this.loading = false;
                });
        },
        setUrl(url) {
            if (url) {
                this.localBannerUrl[this.locale] = url;
            } else {
                this.localBannerUrl[this.locale] = null;
            }
        }
    },
};
</script>

<template>
    <vee-form ref="bannerForm">
        <v-card class="mt-8">
            <v-card-text>
                <h2 class="text-h6 mb-3">{{ $t('TestamentCompletedBanner.title') }}</h2>

                <language-selector
                    v-model="locale"
                    :languages="languages"
                    separator="_"
                />

                <v-text-field
                    :model-value="translatedBannerUrl"
                    class="mt-4"
                    :hint="$t('TestamentCompletedBanner.bannerUrlHint')"
                    :label="$t('TestamentCompletedBanner.bannerUrlLabel')"
                    persistent-hint
                    @update:model-value="setUrl"
                />

                <v-row class="mt-4">
                    <v-col cols="12" lg="6">
                        <h2 class="text-h6 mb-3">{{ $t('TestamentCompletedBanner.bannerDesktopTitle') }}</h2>
                        <v-alert
                            class="mb-2"
                            color="info"
                            density="compact"
                            variant="tonal"
                        >
                            {{ $t('TestamentCompletedBanner.bannerDesktopInfo') }}
                        </v-alert>
                    </v-col>
                    <v-col>
                        <div v-if="translatedBanner.desktop" class="pa-4 rounded border-2 border-dashed">
                            <v-img :src="translatedBanner.desktop.url" />
                            <v-btn
                                class="mt-2"
                                density="comfortable"
                                :text="$t('TestamentCompletedBanner.delete')"
                                variant="text"
                                @click="deleteFile(translatedBanner.desktop, 'desktop')"
                            >
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </div>
                        <vee-field v-else v-slot="{ componentField, errors }" name="desktop">
                            <v-file-upload
                                :error-messages="errors"
                                :v-model="componentField"
                                clearable
                                density="comfortable"
                                name="desktop"
                                @update:model-value="uploadFile($event, 'desktop')"
                            />
                            <v-alert v-if="errors.length > 0" type="error">
                                <div v-for="(error, index) in errors" :key="index">
                                    {{ error }}
                                </div>
                            </v-alert>
                        </vee-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" lg="6">
                        <h2 class="text-h6 mb-3">{{ $t('TestamentCompletedBanner.bannerMobileTitle') }}</h2>
                        <v-alert
                            class="mb-2"
                            color="info"
                            density="compact"
                            variant="tonal"
                        >
                            {{ $t('TestamentCompletedBanner.bannerMobileInfo') }}
                        </v-alert>
                    </v-col>
                    <v-col>
                        <div v-if="translatedBanner.mobile" class="pa-4 rounded border-2 border-dashed">
                            <v-img :src="translatedBanner.mobile.url" />
                            <v-btn
                                class="mt-2"
                                density="comfortable"
                                :text="$t('TestamentCompletedBanner.delete')"
                                variant="text"
                                @click="deleteFile(translatedBanner.mobile, 'mobile')"
                            >
                                <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                        </div>
                        <vee-field v-else v-slot="{ componentField, errors }" name="mobile">
                            <v-file-upload
                                :error-messages="errors"
                                :v-model="componentField"
                                clearable
                                density="comfortable"
                                name="mobile"
                                @update:model-value="uploadFile($event, 'mobile')"
                            />
                            <v-alert v-if="errors.length > 0" type="error">
                                <div v-for="(error, index) in errors" :key="index">
                                    {{ error }}
                                </div>
                            </v-alert>
                        </vee-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="mb-2 ml-2">
                <v-btn :loading="loading" @click="saveContent">
                    <v-icon start>mdi-content-save</v-icon>
                    {{ $t('OneWord.save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </vee-form>
</template>

<style scoped></style>